/* You can add global styles to this file, and also import other style files */
// .k-treeview>ul>li>.k-treeview-top .k-checkbox-wrap,
// .k-treeview>ul>li>.k-treeview-mid .k-checkbox-wrap,
// .k-treeview>ul>li>.k-treeview-bot .k-checkbox-wrap {
//     display: none;
// }

// .legends_block .ps_fs_block {
//   background: #fff;
//   color: #000;
//   font-size: 14px;
//   font-weight: bold;
//   padding: 3px 12px;
//   line-height: 14px;
//   cursor: default;
//   span {
//     margin: 0;
//     padding: 0;
//   }
// }

.grid-selectall-checkbox-container {
  // display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.everyday_checkbox .k-checkbox-label {
  width: auto;
}

.icon-cursor-pointer {
  cursor: pointer;
}